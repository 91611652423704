<template>
  <div>
    <CRow>
      <CCol sm="6" md="4">
        <CCard>
          <CCardHeader
            ><CIcon :content="$options.freeSet.cilBookmark" /> Security KPI
            Updates
          </CCardHeader>
          <CCardBody>
            <strong>2022-05-24:</strong>
            <br/><ul>
              <li><strong>InspectorV2 : Add missing regions </strong>  </li>
            </ul>
            <strong>2022-05-18:</strong>
            <br/><ul>
              <li><strong>EncryptionAtRest_Agg :</strong> Add ElasticSearch, Elasticache, Redshift, Workspaces, SQS, Kinesis, Kinesis Firehose, Backup, Glue, Storage gateway (volume, tape, fileshare), Xray, DynamoDB in aggregated KPI </li>
              <li><strong>EncryptionInTransit_Agg :</strong> Add Elasticache, Glue, Storage gateway fileshare, SageMaker in aggregated KPI </li>
              <li><strong>S3 :</strong> New KPI on bucket policies (they must disallow obsolete or absent TLS) </li>
            </ul>
            <strong>2022-05-13:</strong>
            <br/><ul>
              <li><strong>EC2 :</strong> Instances pending or recently terminated are now ignored </li>
            </ul>
            <strong>2022-04-07:</strong>
            <br/><ul>
              <li><strong>Cloudfront :</strong> WAFv1 removal, WAFAnyVersionValid now counts only WAFv2 </li>
              <li><strong>PubApp :</strong> WAFv1 removal, WAFAnyVersionValid now counts only WAFv2 </li>
            </ul>
            <strong>2022-03-22:</strong>
            <br/><ul>
              <li><strong>All :</strong> Display scope and sub-scope for entities </li>
              <li><strong>Inspector :</strong> New KPIs on Inspector Critical/High findings </li>
            </ul>
            <strong>2022-02-28:</strong>
            <br/><ul>
              <li><strong>Shield :</strong> Fix broken KPI on DRT role configuration </li>
            </ul>
            <strong>2022-02-03:</strong>
            <br/><ul>
              <li><strong>Certified :</strong> All account admins must now be certified </li>
              <li><strong>PubAppAndAPI :</strong> Add KPI on TLS version (will replace XLB:UnEncrypted) </li>
              <li><strong>Cloudfront :</strong> Add KPI on TLS version </li>
            </ul>
            <strong>2022-02-01:</strong>
            <br/><ul>
              <li><strong>SQS :</strong> Fix: handle managed SQS encryption  </li>
              <li><strong>AppSync :</strong> AppSync now counted in PubAppAndAPI KPIs </li>
            </ul>
            <strong>2022-01-31:</strong>
            <br />
            <ul>
              <li>
                <strong>EC2 :</strong> Instances with a public Elastic IP are
                now considered as public
              </li>
              <li><strong>EC2 :</strong> Add SSM agent KPIs</li>
              <li><strong>EC2 :</strong> Add Permission KPIs</li>
              <li>
                <strong>EC2 :</strong> Add Inspector v2 KPI, Inspector v1 KPIs
                now only apply to instances not using v2
              </li>
            </ul>
            <strong>2022-01-21:</strong>
            <br />
            <ul>
              <li>
                <strong>API Gateway V2 :</strong> API Gateway V2 now counted in
                PubAppAndAPI KPIs
              </li>
              <li><strong>EC2 :</strong> fix missing instances from data</li>
            </ul>
            <strong>2022-01-10:</strong>
            <br />
            <ul>
              <li>
                <strong>Cloudfront :</strong> Cloudfront:WithWAF now only counts
                WAFv1 (see Cloudfront:WithWAFv2)
              </li>
              <li>
                <strong>EC2 :</strong> Subnet:DefaultNACL now only counts in
                global score for public subnets
              </li>
            </ul>
            <strong>2021-11-24:</strong>
            <br />
            <ul>
              <li>
                <strong>EC2 :</strong> Rename IMDSv2NotRequired ->
                IMDSv2NotEnforced for clarity
              </li>
            </ul>
            <strong>2021-10-28:</strong>
            <br />
            <ul>
              <li>
                <strong>WAFv2 :</strong> WAFv2 count rules removed from
                WAFAnyVersionValid in CloudFront
              </li>
              <li>
                <strong>SG :</strong> rules with prefix lists are now taken into
                consideration
              </li>
              <li>
                <strong>NACLxSG :</strong> only inbound NACLs are now considered
              </li>
            </ul>
            <strong>2021-10-25:</strong>
            <br />
            <ul>
              <li>
                <strong>WAFv1 :</strong> New KPI to track public resources
                behind WAFv1 ACLs using Count rules
              </li>
              <li>
                <strong>WAF :</strong> Security score now includes WAFv1 and
                WAFv2 ACLs using Count rules
              </li>
            </ul>
            <strong>2021-10-08:</strong>
            <br />
            <ul>
              <li>
                <strong>EC2 :</strong> New KPI to track instances not require
                the use of IMDSv2
              </li>
            </ul>
            <strong>2021-10-06:</strong>
            <br />
            <ul>
              <li>
                <strong>Fix :</strong> Fixed display issues of Inspector KPIs
                showing up in red because of exceptions
              </li>
              <li>
                <strong>EC2 :</strong> New column to display EC2 instances in
                regions not supported by Inspector yet
              </li>
              <li>
                <strong>WAF :</strong> New KPI to track public resources behind
                WAF ACLs using Count rules
              </li>
            </ul>
            <strong>2021-09-10:</strong>
            <br />
            <ul>
              <li>
                <strong>Hotfix :</strong> Fixed issue in CloudTrail data format
                causing KPI to be not compliant for all accounts
              </li>
            </ul>
            2021-05-28:
            <br />
            <ul>
              <li>
                <strong>IAM :</strong> Fixed Age of credentials that weren't
                checked properly
                <strong>Increase in KPI and score expected</strong>
              </li>
            </ul>
            2021-05-19:
            <br />
            <ul>
              <li>
                <strong>Hotfix :</strong> Fixed detail account view / Pagination
                errors
              </li>
              <li>
                <strong>Hotfix :</strong> New deployment CERTAccess Role hited
                rate limit causing corrupted KPIs, Corrected at 9AM UTC
              </li>
            </ul>
            2021-05-12:
            <br />
            <ul>
              <li>
                <strong>Global :</strong> All non compliant ressources are now
                available on S3:datacert
              </li>
              <li>
                <strong>Global :</strong> Now only important KPIs are colored,
                the other ones are for information
              </li>
              <li>
                <strong>Global :</strong> All tips have been rewritten, expand
                the help view and click on any KPI to have more infos
              </li>
              <li><strong>EC2 :</strong> More ports checked in a new KPI</li>
              <li>
                <strong>EC2 :</strong> Now inherits exception from Autoscaling
                groups
              </li>
              <li>
                <strong>NATGW :</strong> Network Firewall check implemented,
                update your exceptions
              </li>
              <li><strong>NETWORK-FIREWALL :</strong> New KPIs</li>
              <li><strong>VPC :</strong> New KPIs</li>
            </ul></CCardBody
          >
        </CCard>
      </CCol>
      <CCol sm="6" md="4">
        <CCard>
          <CCardHeader
            ><CIcon :content="$options.freeSet.cilBookmark" /> Release Note v{{
              $store.getters.version
            }}</CCardHeader
          >
          <CCardBody>
            <strong>2022-01:</strong> <br />
            <ul>
              <li>
                <strong>SUPPORT COST:</strong> Starting January 2022, Support
                Cost percentage will be updated to 3,5%.
              </li>
              <li>
                Previously at 5%, it will now be at 3,5%. This update is based
                on the aws support calculation system. The support rate
                decreases as the consumption increases. Thanks to the increase
                in cloud aws consumption, we can now chargeback a lower rate to
                entities
              </li>
              <li>
                As a reminder support is applied to all workload (Marketplace
                and Credits excluded)
                https://aws.amazon.com/premiumsupport/pricing/
              </li>
              <li>
                The support cost adjustment is not retroactive and will only be
                applied from January 2022. For 2021, the charged rate remains
                5%.
              </li>
            </ul></CCardBody
          >
        </CCard>
      </CCol>
      <CCol sm="6" md="4">
        <CCard>
          <CCardHeader
            ><CIcon :content="$options.freeSet.cilNotes" />
            Documentation</CCardHeader
          >
          <CCardBody
            >CCoE Documentation contains best practices, guidance, security
            requirements about AWS in the ENGIE context. You will find all
            usefull links
            <b
              ><a @click="$router.push({ path: '/help' })">here</a></b
            ></CCardBody
          >
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { freeSet } from "@coreui/icons";

export default {
  name: "Dashboard",
  freeSet,
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
